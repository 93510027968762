import React from 'react';

import classes from './Footer.module.scss';

export const Footer: React.FC = function() {
    return (
        <div className={classes.footer}>
        </div>
    );
};
